<template>
  <div class="position-relative">
    <Loading v-if="loading"></Loading>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="table"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Rows per Page',
      }"
    >
      <template v-slot:top>
        <v-row class="py-0 my-0">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              hide-details
              light
              :placeholder="$t('general.Search')"
              outlined
              color="rgba(0,0,0,.6)"
              class="
                mx-4
                mt-0
                pt-3
                font-size-input
                placeholder-lighter
                text-light-input
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :class="classLang">
            <div class="mx-10 mt-0 pt-3 text-h5 text-typo font-weight-bolder">
              {{ $t("total") }} : {{ items.length }}
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.photo="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1">
            <v-list-item-avatar rounded :size="36" class="my-0 ml-0">
              <v-img
                v-if="item.photo"
                :alt="item.photo"
                :src="item.photo"
                class="border-radius-lg"
              ></v-img>
              <v-img
                v-else
                src="@/assets/img/alborj/no-avatar.jpg"
                alt="Avatar"
                class="border-radius-lg"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="mb-0 text-sm text-typo font-weight-bold"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:item.image="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1">
            <a :href="item.image" target="__blank">
              <v-img
                v-if="item.image"
                :alt="item.image"
                :src="item.image"
                style="width: 150px"
                class="border-radius-lg"
              ></v-img>
            </a>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:item.answer="{ item }" :id="item.id">
        <v-list class="py-0">
          <v-list-item class="px-0 py-1">
            <a
              :href="$baseURL + item.answer"
              class="text-decoration-none"
              target="__blank"
              v-if="item.type == 'file'"
            >
              <v-btn
                icon
                class="shadow-0 px-0"
                small
                style="color: black"
                simple
              >
                <v-icon size="18" class="rotate-180"
                  >fas fa-file-pdf text-lg me-1</v-icon
                >
                {{ $t("ticket.file") }}
              </v-btn>
            </a>
            <div v-else>
              {{ item.answer }}
            </div>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:item.chips_level="{ item }" :id="item.id">
        <div>
          <v-chip
            v-if="item.importance_level == 1"
            label
            outlined
            color="orange"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{
              item.importance_level_text
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.importance_level == 2"
            label
            outlined
            color="green"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{
              item.importance_level_text
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.importance_level == 3"
            label
            outlined
            color="red"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0">{{
              item.importance_level_text
            }}</span>
          </v-chip>
        </div>
      </template>

      <template v-slot:item.quote="{ item }" :id="item.id">
        <div v-html="item.quote"></div>
      </template>

      <template v-slot:item.body="{ item }" :id="item.id">
        <div v-html="item.body"></div>
      </template>

      <template v-slot:item.btns="{ item }" :id="item.id">
        <div class="d-flex justify-content-center" :id="item.id">
          <div v-for="(btn, index) in btns" :key="index">
            <div class="d-inline-block" v-if="btn.permission">
              <span
                v-if="
                  $store.getters['auth/str_per'].indexOf(btn.permission) > -1
                "
                :title="$t('general.' + btn.text.replace('_', ' '))"
                :class="`btn font-weight-bold  mr-4`"
                @click="action(item.id, btn.text, item)"
              >
                <!-- <v-btn :title="$t('general.'+btn.text.replace('_',' '))" :class="`w-45 font-weight-bold py-2 px-1 mr-2 text-white ${btn.color}`" -->
                <!-- $store.state.form.loader -->
                <v-icon v-if="btn.type == 'icon'">{{ btn.icon }}</v-icon>
                <div v-else>
                  {{ $t("general." + btn.text.replace("_", " ")) }}
                </div>
              </span>
            </div>
            <div class="d-inline-block" v-else>
              <span
                :title="$t('general.' + btn.text.replace('_', ' '))"
                :class="`btn font-weight-bold  mr-4`"
                @click="action(item.id, btn.text, item)"
              >
                <!-- <v-btn :title="$t('general.'+btn.text.replace('_',' '))" :class="`w-45 font-weight-bold py-2 px-1 mr-2 text-white ${btn.color}`" -->
                <!-- $store.state.form.loader -->
                <v-icon v-if="btn.type == 'icon'">{{ btn.icon }}</v-icon>
                <div v-else>
                  {{ $t("general." + btn.text.replace("_", " ")) }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Loading from "../Components/Loading.vue";
export default {
  name: "Table",
  props: ["headers", "items", "btns", "loading", "data"],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
  },
  components: {
    Loading,
  },
  methods: {
    filterOnlyCapsText(value, search, item) {
      console.log("search");
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    action: function (id, name, item) {
      this.$emit("action", id, name, item);
    },
  },
};
</script>
<style>
.v-data-table {
  border-radius: 0 !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
.theme--light.v-pagination .v-pagination__item--active {
  background-image: linear-gradient(310deg, #047a6f, #2fbcae) !important;
}
</style>