var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[(_vm.loading)?_c('Loading'):_vm._e(),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"footer-props":{
      'items-per-page-text': 'Rows per Page',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"py-0 my-0"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"\n              mx-4\n              mt-0\n              pt-3\n              font-size-input\n              placeholder-lighter\n              text-light-input\n            ",attrs:{"hide-details":"","light":"","placeholder":_vm.$t('general.Search'),"outlined":"","color":"rgba(0,0,0,.6)"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{class:_vm.classLang,attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"mx-10 mt-0 pt-3 text-h5 text-typo font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("total"))+" : "+_vm._s(_vm.items.length)+" ")])])],1)]},proxy:true},{key:"item.photo",fn:function(ref){
    var item = ref.item;
return [_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"px-0 py-1"},[_c('v-list-item-avatar',{staticClass:"my-0 ml-0",attrs:{"rounded":"","size":36}},[(item.photo)?_c('v-img',{staticClass:"border-radius-lg",attrs:{"alt":item.photo,"src":item.photo}}):_c('v-img',{staticClass:"border-radius-lg",attrs:{"src":require("@/assets/img/alborj/no-avatar.jpg"),"alt":"Avatar"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-0 text-sm text-typo font-weight-bold"},[_vm._v(_vm._s(item.name))])],1)],1)],1)]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"px-0 py-1"},[_c('a',{attrs:{"href":item.image,"target":"__blank"}},[(item.image)?_c('v-img',{staticClass:"border-radius-lg",staticStyle:{"width":"150px"},attrs:{"alt":item.image,"src":item.image}}):_vm._e()],1)])],1)]}},{key:"item.answer",fn:function(ref){
    var item = ref.item;
return [_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"px-0 py-1"},[(item.type == 'file')?_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.$baseURL + item.answer,"target":"__blank"}},[_c('v-btn',{staticClass:"shadow-0 px-0",staticStyle:{"color":"black"},attrs:{"icon":"","small":"","simple":""}},[_c('v-icon',{staticClass:"rotate-180",attrs:{"size":"18"}},[_vm._v("fas fa-file-pdf text-lg me-1")]),_vm._v(" "+_vm._s(_vm.$t("ticket.file"))+" ")],1)],1):_c('div',[_vm._v(" "+_vm._s(item.answer)+" ")])])],1)]}},{key:"item.chips_level",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.importance_level == 1)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"orange"}},[_c('span',{staticClass:"text-caption ls-0"},[_vm._v(_vm._s(item.importance_level_text))])]):_vm._e(),(item.importance_level == 2)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"green"}},[_c('span',{staticClass:"text-caption ls-0"},[_vm._v(_vm._s(item.importance_level_text))])]):_vm._e(),(item.importance_level == 3)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"red"}},[_c('span',{staticClass:"text-caption ls-0"},[_vm._v(_vm._s(item.importance_level_text))])]):_vm._e()],1)]}},{key:"item.quote",fn:function(ref){
    var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.quote)}})]}},{key:"item.body",fn:function(ref){
    var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.body)}})]}},{key:"item.btns",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center",attrs:{"id":item.id}},_vm._l((_vm.btns),function(btn,index){return _c('div',{key:index},[(btn.permission)?_c('div',{staticClass:"d-inline-block"},[(
                _vm.$store.getters['auth/str_per'].indexOf(btn.permission) > -1
              )?_c('span',{class:"btn font-weight-bold  mr-4",attrs:{"title":_vm.$t('general.' + btn.text.replace('_', ' '))},on:{"click":function($event){return _vm.action(item.id, btn.text, item)}}},[(btn.type == 'icon')?_c('v-icon',[_vm._v(_vm._s(btn.icon))]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("general." + btn.text.replace("_", " ")))+" ")])],1):_vm._e()]):_c('div',{staticClass:"d-inline-block"},[_c('span',{class:"btn font-weight-bold  mr-4",attrs:{"title":_vm.$t('general.' + btn.text.replace('_', ' '))},on:{"click":function($event){return _vm.action(item.id, btn.text, item)}}},[(btn.type == 'icon')?_c('v-icon',[_vm._v(_vm._s(btn.icon))]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("general." + btn.text.replace("_", " ")))+" ")])],1)])])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }